<template>
  <div class="users">
    <users-header />
    <users-list />
  </div>
</template>

<script>
import UsersHeader from '@/pages/users/UsersHeader.vue'
import UsersList from '@/pages/users/UsersList.vue'

export default {
  components: {
    UsersHeader,
    UsersList,
  },
}
</script>

<style lang="scss" scoped>
.users {
  max-width: 1440px;
  margin: 0px auto;
}
</style>
