<template>
  <div class="users-header">
    <img
      alt="users-header"
      class="image"
      src="img/backgrounds/img-header-users-1920.webp"
      srcset="
        img/backgrounds/img-header-users-768.webp   768w,
        img/backgrounds/img-header-users-1264.webp 1264w,
        img/backgrounds/img-header-users-1920.webp 1920w
      "
      sizes="
          (max-width: 768px) 768px,
          (max-width: 1264px) 1264px,
          (max-width: 1920px) 1920px,
          1920px
        "
    />
    <div class="d-flex justify-space-between align-end image__title">
      <div class="df-flex-sm df-flex-col">
        <h1>{{ $t('configurations') }}</h1>
        <h2>{{ $t('user.user') }}</h2>
      </div>
      <v-btn
        v-if="isAdmin"
        class="white--text text-none button-register-confirm"
        text
        @click="openFormRegisterUser"
      >
        <span v-if="!isMobile" class="mr-2">{{ $t('register_') }}</span>
        <v-icon class="icon-plus">mdi-plus</v-icon>
      </v-btn>
    </div>
    <modal-user ref="modalUser" />
  </div>
</template>

<script>
import LayoutMixin from '@/components/LayoutMixin.vue'
import ModalUser from '@/pages/users/ModalUser.vue'
import { logEvent, events } from '@/services/analytics'
import { mapGetters } from 'vuex'

export default {
  name: 'UsersHeader',

  mixins: [LayoutMixin],

  components: {
    ModalUser,
  },

  computed: {
    ...mapGetters('user', ['isAdmin']),
  },

  methods: {
    openFormRegisterUser() {
      this.$refs.modalUser.openModal()
      logEvent(events.user.register)
    },
  },
}
</script>

<style lang="scss" scoped>
.users-header {
  position: relative;
  border-radius: 0px 0px 8px 8px;
  overflow: hidden;
  height: 200px;

  @include d(m) {
    height: 160px;
  }
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  .image__title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    padding: 0px 24px;
    font-family: 'Rubik';
    font-style: normal;
    color: #ffffff;

    @include d(m) {
      padding: 0px 12px;
    }
    h1 {
      color: #62cc70;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;

      @include d(m) {
        font-size: 14px;
        line-height: 16px;
      }
    }
    h2 {
      font-size: 36px;
      line-height: 40px;
    }
    .icon-plus {
      font-size: 1.2rem;
    }
    .button-register-confirm {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0;
      background-color: #39af49 !important;
      border-color: #39af49;
    }
  }
}
</style>
